<template>
  <AuthLayout>
    <h1>Create your account</h1>
    <p class="my-2">Lets explore the best opportunities</p>

    <ValidationObserver v-slot="{ invalid }">
      <form @submit.prevent="handleSubmit">
        <!-- <Input
          class="mb-2"
          type="text"
          placeholder="Full Name"
          v-model="formData.fullName"
          name="Full name"
          rules="required|min:3|fullName"
        /> -->

        <!-- <Input
          class="mb-2"
          type="text"
          placeholder="Username"
          v-model="formData.username"
          name="Username"
          rules="required|min:3"
        /> -->

        <Input
          class="mb-2"
          type="email"
          placeholder="Email Address"
          v-model.trim="formData.email"
          name="Email"
          rules="required|email"
        />
        <Input
          class="mb-2"
          type="password"
          placeholder="Password"
          v-model="formData.password"
          name="Password"
          rules="required|between:6,21"
          autocomplete="true"
        />
        <Button :loading="loading" :disabled="invalid || loading" theme="blue">
          Create Account
        </Button>
      </form>
    </ValidationObserver>

    <!-- <div class="flex flex__space-center">
      <div style="width: 43%; background: #d3d4d8; height: 1px"></div>
      <p class="text__light-grey my-3">OR</p>
      <div style="width: 43%; background: #d3d4d8; height: 1px"></div>
    </div>

    <button class="cta__google u-relative mb-2" @click="continueWithGoogle">
      Continue with Google
      <span class="cta__google-icon"
        ><img src="../../../assets/icons/google-icon.svg" alt="google-icon"
      /></span>
    </button> -->

    <p style="font-size: 1.2rem" class="text__light-grey mt-4">
      All your activity will remain private
    </p>

    <template #footer>
      <p class="my-4 text-center">Having troubles signing up?</p>
    </template>
  </AuthLayout>
</template>

<script>
import Button from '../../../components/Button';
import AuthLayout from '../../../layouts/AuthLayout';
import Input from '../../../components/Input';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import authService from '../../../services/api/authService';
import { setPrivateHaders } from '../../../services';

export default {
  name: 'CreateAccount',
  components: {
    AuthLayout,
    Input,
    Button
  },
  data() {
    return {
      formData: {
        email: '',
        fullName: '',
        password: '',
        role: 'seeker'
      },
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      loggedUser: 'auth/getLoggedUser'
    })
  },
  methods: {
    ...mapActions({
      // TODO: remove createJobSeeker and sendOTP actions from store
      // createJobSeeker: "auth/createJobSeeker",
      // sendOTP: "apimodel/makeRequest"
    }),
    ...mapMutations({
      setLoggedUser: 'auth/setLoggedUser',
      setUserData: 'authnew/setUserData'
    }),
    async handleSubmit() {
      this.loading = true;

      authService
        .register(this.formData)
        .then((res) => {
          this.$handleSuccess(res);
          this.setUserData(res.data);
          localStorage.setItem('otpReceivedAt', Date.now());
          setPrivateHaders(res.data.token);
          setTimeout(() => {
            this.$router.push({
              name: 'verifyEmail'
            });
          }, 2000);
        })
        .catch((e) => {
          this.$handleError(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async continueWithGoogle() {
      location.href = 'https://api.infohob.com/auth/google';
    }
  }
};
</script>

<style scoped></style>
